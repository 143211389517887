import { SingleWorkObject, SingleWorkNode, ImageObject, ImageNode, WorkObject } from 'types'

const getImagesArray = (array: ImageNode[]): ImageObject[] =>
  array.map(({ id, description, file }) => {
    const image = {
      id,
      alt: description,
      url: file.url,
    }

    return image
  })

export const sort = <T extends SingleWorkObject | WorkObject>(array: T[]): T[] =>
  array.sort((a, b) => {
    if (a.position < b.position) {
      return -1
    }

    return 0
  })

export const getFormattedWork = ({
  contentfulPortfolioItem,
}: {
  contentfulPortfolioItem: SingleWorkNode
}): SingleWorkObject => {
  const {
    id,
    entryTitle,
    url,
    color,
    tags,
    pageHeaderFirst,
    pageHeaderSecond,
    excerptFIrst,
    excerptSecond,
    rolePlayed,
    resume,
    mainImage,
    sliderImages,
    position,
  } = contentfulPortfolioItem

  const formattedWork: SingleWorkObject = {
    title: entryTitle,
    id,
    color,
    url,
    tags,
    position,
    pageHeader: {
      title: {
        first: pageHeaderFirst,
        second: pageHeaderSecond,
      },
      excerpt: {
        first: excerptFIrst.excerptFIrst,
        second: excerptSecond?.excerptSecond,
      },
    },
    role: rolePlayed,
    resume: resume.resume,
    mainImage: {
      alt: mainImage.description,
      url: mainImage.file.url,
    },
    images: getImagesArray(sliderImages),
  }

  return formattedWork
}
