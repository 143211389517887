import React from 'react'
import Helmet from 'react-helmet'
import logo from '@images/logo_dark.svg'

type Props = {
  title: string
  description: string
  themeColor: string
}

const imageUrl =
  'https://images.ctfassets.net/fp2sytedawfu/4c6tEwjIaCZPsLYT81y5Tm/5b72ef8176c96667ab68b12972090b78/Logo_Eiker_Yejan.jpg'

const Seo: React.FC<Props> = ({ title, description, themeColor }) => (
  <Helmet>
    <meta name="description" content={description} />
    <meta
      name="keywords"
      content="web developer, front-end developer, react, wordpress, php, javascript, web development"
    />
    <meta name="theme-color" content={themeColor} />
    <title>{title}</title>
    <link rel="icon" href={logo} />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    <meta name="twitter:image" content={imageUrl} />
    <meta property="og:image" content={imageUrl} />
    <meta property="og:type" content="website" />
    <meta property="og:description" content={description} />
    <meta property="og:title" content={title} />
    <meta property="og:url" content={typeof window !== 'undefined' ? window.location.href : ''} />
  </Helmet>
)

export default Seo
