import React, { useEffect, useRef, useState } from 'react'
import Arrow from '@partials/Arrow'
import SwiperCore, { Lazy, EffectFade, Pagination, Navigation } from 'swiper'
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react'

import 'swiper/css'
import 'swiper/css/lazy'
import 'swiper/css/effect-fade'
import 'swiper/css/pagination'
import '../../assets/styles/components/Slider.scss'

SwiperCore.use([Lazy, EffectFade, Pagination, Navigation])

type Props = {
  children: React.ReactElement | React.ReactElement[]
  className: string
  settings?: SwiperProps
  animate?: boolean
  mouseControl?: boolean
  onChange?: (swiper: SwiperCore) => void
}

const Slider: React.FC<Props> = ({ children, className, settings, animate, mouseControl, onChange }) => {
  const [currentSwiper, setCurrentSwiper] = useState<SwiperCore>()

  const navigationNextButton = useRef<HTMLDivElement>(null)
  const navigationPrevButton = useRef<HTMLDivElement>(null)

  const sliderSettings: SwiperProps = {
    ...settings,
    draggable: true,
    speed: 800,
    watchSlidesProgress: true,
    navigation: settings?.navigation
      ? {
          nextEl: navigationNextButton.current,
          prevEl: navigationPrevButton.current,
        }
      : undefined,
  }

  function handleArrowKeys(e: KeyboardEvent) {
    if (currentSwiper) {
      const key = e.code
      const dir = settings?.direction ?? 'horizontal'
      const slideNext = () => currentSwiper.slideNext()
      const slidePrev = () => currentSwiper.slidePrev()

      if (dir === 'horizontal') {
        if (key === 'ArrowLeft') slidePrev()
        if (key === 'ArrowRight') slideNext()
      }

      if (dir === 'vertical') {
        if (key === 'ArrowUp') slidePrev()
        if (key === 'ArrowDown') slideNext()
      }
    }
  }

  const mouseWheelControl = (e: WheelEvent, swiper: SwiperCore): void => {
    const delta = Math.sign(e.deltaY)

    if (!swiper.animating) {
      if (delta === 1) {
        return swiper.slideNext()
      }

      return swiper.slidePrev()
    }
  }

  const doAnimation = (index: number) => {
    if (index !== undefined) {
      // Animation classes
      const classes = ['animated', 'fadeIn']

      // Get all slides
      const slides = document.querySelectorAll('.swiper-slide')

      slides.forEach((slide) => {
        const elements = slide.querySelectorAll('.animate')
        elements.forEach((el) => el.classList.remove(...classes))
      })

      // Get active slide
      const slide = slides[index]

      // Animate slide
      const shouldAnimate = slide.querySelectorAll('.animate')

      shouldAnimate.forEach((el) => el.classList.add(...classes))
    }
  }

  useEffect(() => {
    if (currentSwiper) {
      if (mouseControl) {
        window.addEventListener('wheel', (e) => mouseWheelControl(e, currentSwiper))
      }
      window.addEventListener('keydown', handleArrowKeys)

      return () => {
        window.removeEventListener('wheel', (e) => mouseWheelControl(e, currentSwiper))
        window.removeEventListener('keydown', handleArrowKeys)
      }
    }
  }, [currentSwiper])

  return (
    <div className={`swiper-container ${className || ''}`}>
      <Swiper
        {...sliderSettings}
        onSwiper={setCurrentSwiper}
        onInit={() => {
          if (animate) {
            doAnimation(0)
          }
        }}
        onSlideChange={(swiper) => {
          if (onChange) onChange(swiper)
        }}
        onSlideChangeTransitionEnd={(swiper) => {
          if (animate) {
            doAnimation(swiper.activeIndex)
          }
        }}
        className="swiper-wrapper"
      >
        {React.Children.map<React.ReactNode, any>(children, (slide) => (
          <SwiperSlide>{React.cloneElement(slide, {})}</SwiperSlide>
        ))}
      </Swiper>
      {settings?.navigation ? (
        <div className="swiper-nav-wrapper">
          <div ref={navigationPrevButton} className="swiper-button-prev">
            <Arrow />
          </div>
          <div ref={navigationNextButton} className="swiper-button-next">
            <Arrow />
          </div>
        </div>
      ) : null}
      <div className="swiper-pagination" />
    </div>
  )
}

export default Slider
